@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fadeIn {
  animation: fadeInAnimation 1s;
}

.slick-dots li.slick-active button:before {
  color: white !important;
}

.slick-slide > div > div {
  display: flex !important;
}

.slick-slide a div {
  min-height: 35px;
  max-height: 35px;
}

.two-lines {
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
  overflow: hidden;
}
